import * as React from "react";
import { useNavigate } from "react-router-dom";

//Styles
import { IntroDatasetStyle } from "styles";
//Icons
import MenuIcon from "@mui/icons-material/Menu";
import { Chitralekhalogo } from "assets/profileImages/index";
import BhashiniLogo from "../assets/bhashini/bhashini-logo.png";
import MeityLogo from "../assets/bhashini/Meity-logo.png";

//Components
import {
  Grid,
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  IconButton,
  Button,
} from "@mui/material";

const drawerWidth = 240;

function IntroHeader(props) {
  const classes = IntroDatasetStyle();
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", mt: 3 }}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <img src={Chitralekhalogo} style={{ maxWidth: "90px" }} alt="logo" />
        </Grid>
        <Grid item>
          <Button
            onClick={() => navigate("/")}
            sx={{ width: "80%", color: "#000000" }}
          >
            Home
          </Button>
        </Grid>
        <Grid item>
          <Button
            href="https://bhashini.gov.in/about-bhashini"
            target="_blank"
            sx={{ width: "80%", color: "#000000" }}
          >
            About us
          </Button>
        </Grid>
        <Grid item>
          <Button
            href="https://bhashini.gov.in/prayog"
            target="_blank"
            sx={{ width: "80%", color: "#000000" }}
          >
            Prayog
          </Button>
        </Grid>
        <Grid item>
          <Button
            href="https://bhashini.gov.in/sahyogi"
            target="_blank"
            sx={{ width: "80%", color: "#000000" }}
          >
            Sahyogi
          </Button>
        </Grid>
        <Grid item>
          <Button
            href="https://bhashini.gov.in/sanchalak"
            target="_blank"
            sx={{ width: "80%", color: "#000000" }}
          >
            Sanchalak
          </Button>
        </Grid>
        <Grid item>
          <Button
            href="https://bhashini.gov.in/pravakta"
            target="_blank"
            sx={{ width: "80%", color: "#000000" }}
          >
            Pravakta
          </Button>
        </Grid>
        <Grid item>
          <Button
            href="https://bhashini.gov.in/tender"
            target="_blank"
            sx={{ width: "80%", color: "#000000" }}
          >
            Tenders/ EOI
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          background: "#FFF",
          padding: "15x 0px 0px 50px",
          alignItems: "center",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "#FFFFFF",
            paddingX: { xs: 2, sm: "12px" },
            paddingY: 1,
            maxWidth: "1320px",
          }}
        >
          <Grid item xs={12} sm="auto">
            <picture>
              <img
                src={MeityLogo}
                alt="Meity-logo"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </picture>
          </Grid>
          <Grid item xs={12} sm="auto">
            <picture>
              <img
                src={BhashiniLogo}
                alt="Bhashini-logo"
                height={60}
                style={{ maxWidth: "100%", height: "60" }}
              />
            </picture>
          </Grid>
        </Grid>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            background: "#F5F7FA",
          }}
        >
          <Toolbar
            style={{
              maxWidth: "1320px",
              width: "100%",
              justifyContent: "space-between",
              padding: "0 12px",
              minHeight: "48px",
            }}
          >
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Grid display={"flex"} gap={2}>
                <Button
                  onClick={() => navigate("/")}
                  className={classes.headerMenu}
                >
                  Home
                </Button>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bhashini.gov.in/about-bhashini"
                >
                  <Button className={classes.headerMenu}>About us</Button>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bhashini.gov.in/prayog"
                >
                  <Button className={classes.headerMenu}>Prayog</Button>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bhashini.gov.in/sahyogi"
                >
                  <Button className={classes.headerMenu}>Sahyogi</Button>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bhashini.gov.in/sanchalak"
                >
                  <Button className={classes.headerMenu}>Sanchalak</Button>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bhashini.gov.in/pravakta"
                >
                  <Button className={classes.headerMenu}>Pravakta</Button>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bhashini.gov.in/tender"
                >
                  <Button className={classes.headerMenu}>Tenders/ EOI</Button>
                </a>
              </Grid>
            </Box>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { md: "none", xs: "flex" },
                color: "black",
                justifyContent: "end",
                ml: 3,
              }}
            >
              <MenuIcon />
            </IconButton>

            <Grid>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://bhashini.gov.in/bhashadaan/en/home"
              >
                <Button
                  variant="contained"
                  className={classes.button}
                  style={{
                    height: "auto",
                    width: "auto",
                    padding: "5px 25px",
                    margin: "3px 0",
                    fontFamily: "Rowdies",
                    fontSize:"16px",
                    background:"#2961AD"
                  }}
                >
                  Bhashadaan
                </Button>
              </a>
            </Grid>
          </Toolbar>
        </Box>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
export default IntroHeader;
