import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//Styles
import { IntroDatasetStyle } from "styles";

//Components
import { Grid, Typography, Button, Box, useMediaQuery } from "@mui/material";
import { Download, Features, Principles } from "containers/intro";
import WelcomeToChitranuvaad from "../assets/bhashini/chitranuvaad-welcome.png";
import FooterNewDesign from "./FooterNewDesign";

const ChitralekhaPortal = () => {
  const classes = IntroDatasetStyle();
  const mobileDevices = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#DBEDFF",
        }}
      >
        <Grid
          container
          direction="row"
          className={classes.section}
          sx={{ maxWidth: "1320px" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              mt: mobileDevices ? 15 : "40px",
              mb: mobileDevices ? "" : "60px",
            }}
          >
            <Typography variant="h2" className={classes.Chitralekhatitle}>
              Effortless Subtitling for Indic Languages with Chitraanuvaad
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "28px",
                fontWeight: "400",
                margin: "0 0 25px 12px",
                textAlign: "justify",
              }}
            >
              Chitraanuvaad, a free platform, adds subtitles to videos in
              multiple Indic languages using machine learning. It supports
              various input sources like YouTube and local files and offers
              different methods for transcription and voice-over generation,
              including models, existing captions, or custom subtitle files.
            </Typography>

            <Grid textAlign={"left"} sx={{ margin: "0 0 25px 12px" }}>
              <Link to={"/login"}>
                <Button variant="contained" className={classes.button}>
                  Sign In
                </Button>
              </Link>
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} sx={{ marginLeft: "auto" }}>
            <Box display={{ xs: "none", md: "inherit" }}>
              <img
                alt="landing page"
                src={WelcomeToChitranuvaad}
                style={{
                  width: "60%",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Principles />
      <Features />
      <Download />
      <FooterNewDesign />
    </div>
  );
};
export default ChitralekhaPortal;
