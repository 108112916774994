export const testimonials = [
    {
      id: "1",
      name: "Vargil",
      role: "Translation Editor",
      organization: "NPTEL",
      shortContent: `My interaction with Chitralekha has been incredibly stimulating and 
      energetic. It enables teachers and content creators to reach a wide range 
      of learners, aiding students in learning the most difficult subjects in their 
      native tongues.`,
      content: `My interaction with Chitralekha has been incredibly stimulating and 
      energetic. It enables teachers and content creators to reach a wide range 
      of learners, aiding students in learning the most difficult subjects in their 
      native tongues. In order to empower and provide every learner in the 
      country with the best educational resource (the NPTEL courses), 
      Chitralekha expands the MHRD's mission and ideology in a way that is 
      both flexible and rational, offering students a much brighter and wider 
      range of opportunities to master the topics. I personally discovered that 
      the tool is continually improving assisting in advanced translations`,
    },
    {
      id: "2",
      name: "Jayashree",
      role: "Translation Editor",
      organization: "NPTEL",
      shortContent: `The process of translation has become much easier and more effective 
      with Chitralekha. The quality of translation is very good and it has 
      considerably reduced our working hours.`,
      content: `The process of translation has become much easier and more effective 
      with Chitralekha. The quality of translation is very good and it has 
      considerably reduced our working hours.`,
    },
    {
      id: "3",
      name: "Naresh",
      role: "Translation Editor and Voice Over Editor",
      organization: "NPTEL",
      shortContent: `I recently translated the NPTEL course "C Programming and Assembly 
      Language" using the Chitralekha tool. I must express my appreciation for 
      the tool, as it delivers the lecture content very nicely in the local 
      language.`,
      content: `I recently translated the NPTEL course "C Programming and Assembly 
      Language" using the Chitralekha tool. I must express my appreciation for 
      the tool, as it delivers the lecture content very nicely in the local 
      language. I am thrilled to see the regional audio output, which I prepared 
      during the voiceover process, the lecture content came out very well. 
      Now, I feel the readers can watch the content in their native language 
      without having to worry about the language barriers and not having to go 
      through long lecture transcripts. 
      The overall application is impressive, and I believe the tool adds great 
      value to the translation and voiceover process.`,
    },
    {
      id: "4",
      name: "Ankur Sehgal",
      role: "Senior Project Lead",
      organization: "Central Square Foundation",
      shortContent: `Employing   the   Chitralekha   tool   for   audio   transcription   has
      revolutionized   the   way   TicTacLearn   operates.   It   has   greatly
      simplified the task of subtitling our video content, allowing for
      seamless conversion of audio material.`,
      content: `Employing   the   Chitralekha   tool   for   audio   transcription   has
      revolutionized   the   way   TicTacLearn   operates.   It   has   greatly
      simplified the task of subtitling our video content, allowing for
      seamless conversion of audio material into written transcripts across
      various Indian languages. The interface has become notably more
      user-friendly, and the tool's automatic transcription features are
      undeniably impressive. I am confident that this tool is at the
      forefront of a crucial transformation in the industry, propelling us
      towards a more inclusive, multilingual and interconnected online
      landscape`,
    },
    {
      id: "5",
      name: "Kavita Rawat",
      role: "Project Manager",
      organization: "Central Square Foundation",
      shortContent: `In the past few months, we have used Chitralekha in multiple
      projects   for   generating   subtitles   for   TicTacLearn   Hindi   YouTube
      videos. The process has been nothing but very smooth and simple.`,
      content: `In the past few months, we have used Chitralekha in multiple
      projects   for   generating   subtitles   for   TicTacLearn   Hindi   YouTube
      videos. The process has been nothing but very smooth and simple.
      98% accuracy in transcription generation makes Chitralekha the
      most effective AI tool in the market right now. Even the rest 2%
      error can be easily resolved using the automatic word generation
      facility   available   in   multiple   scripts,   including   Devanagari.   The
      process of uploading, assigning and reviewing any task on the
      platform   is   very   effortless.   I   am   excited   to   witness   the
      transformations   Chitralekha   is   going   to   bring   through   its
      linguistically inclusive online platform.`,
    },
    {
      id: "6",
      name: "Prithaa More",
      role: "Universal Editor",
      organization: "TicTacLearn",
      shortContent: `Chitralekha has been a very useful tool, in my opinion! It's made
      translating inaccessible video into Hindi much easier. Its interface
      has grown more friendly and I especially appreciate its automatic
      word generation abilities.`,
      content: `Chitralekha has been a very useful tool, in my opinion! It's made
      translating inaccessible video into Hindi much easier. Its interface
      has grown more friendly and I especially appreciate its automatic
      word generation abilities. I feel like Chitralekha is bridging a huge
      gap in the industry and taking a step towards a more inclusive
      online world.`,
    },
    {
      id: "7",
      name: "Srimoukthika",
      role: "Language Expert",
      organization: "AI4Bharat",
      shortContent: `I've had the opportunity to work on the Chitralekha tool for subtitling and 
      translation. Working on this tool was a butter smooth process, especially 
      keeping in mind it was translation to an Indic language.`,
      content: `I've had the opportunity to work on the Chitralekha tool for subtitling and 
      translation. Working on this tool was a butter smooth process, especially 
      keeping in mind it was translation to an Indic language. I must mention 
      the interface of the tool, how it is interesting to navigate through all the 
      technical and linguistic aspects, making the process of subtitling easy and
      accessible. Everything you need is available on screen rather than having 
      to search for them in drop down menus.
      As a subtitling artist, I am aware of the pain involved in adjusting subtitles 
      to the character per second limit/the character per line limit in other 
      conventional subtitling tools. However, Chitralekha provides parallel text 
      boxes and keeps tab on the aforementioned character limit. Navigating 
      across the text boxes containing subtitles, to cross check and review was 
      hassle free, much to my surprise. In addition, the word suggestions make 
      the work easy breezy, not having to worry about the character count or 
      text being input. These features make Chitralekha the first ever such 
      platform in subtitling.
      And for Voice Over, one does not need access to professional studio 
      recording consoles to get the job done. It takes care of recording voice 
      with much needed noise cancellation and gives crisp, clear and excellent 
      audio output, matching the video.
      Overall, Chitralekha is one solution for three tasks and ensures the best 
      output.`,
    },
    {
      id: "8",
      name: "Jaspal Singh",
      role: "Language Expert",
      organization: "AI4Bharat",
      shortContent: `Chitralekha is a user-friendly tool. A user can easily operate all the 
      functions of Chitralekha without any prior training. It is the best software 
      for Transcription, Translation, Subtitling, and Voice Over.`,
      content: `Chitralekha is a user-friendly tool. A user can easily operate all the 
      functions of Chitralekha without any prior training. It is the best software 
      for Transcription, Translation, Subtitling, and Voice Over.`,
    },
    {
      id: "9",
      name: "KRISHNA ARAVIND S",
      role: "Language Expert",
      organization: "AI4Bharat",
      shortContent: `I got an opportunity to translate from English to an Indic Language and 
      lend voice for a very important video. And I'm very happy to say that this 
      is how I learnt about the Chitralekha tool and its nuances.`,
      content: `I got an opportunity to translate from English to an Indic Language and 
      lend voice for a very important video. And I'm very happy to say that this 
      is how I learnt about the Chitralekha tool and its nuances. It was a new 
      and ultimate experience for me. My sincere thanks to the IT support team 
      for helping me sincerely in this process.`,
    },
  ];