import React from "react";

//Styles
import { IntroDatasetStyle } from "styles";
import { introTheme } from "theme";

//Components
import { Grid, Typography, ThemeProvider, Box } from "@mui/material";
import { scalability, simplicity, dynamic, extensible } from "assets/index";

const Principles = () => {
  const classes = IntroDatasetStyle();

  return (
    <ThemeProvider theme={introTheme}>
      <div>
        <Grid item xs={12} sx={{ mt: 9, mb: 1 }}>
          <Typography variant="h4" className={classes.titles}>
            Principles
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="center"
            gap="15px"
            sx={{ mt: 3 }}
          >
            <Grid item xs={12} md={4} className={classes.principlesBox}>
              <Box className={classes.principlesInnerBox}>
                <Box className={classes.principlesImageBox}>
                  <img
                    src={scalability}
                    alt="logo"
                    className={classes.Principlesimg}
                  />
                </Box>

                <Typography
                  variant="h4"
                  sx={{ mt: 1, mb: 1 }}
                  className={classes.principlesTitle}
                >
                  Modular Architecture and Containerization
                </Typography>
              </Box>

              <Typography variant="body2" className={classes.principlesContent}>
                {`${process.env.REACT_APP_NAME}`} has a modular & micro-service
                based architecture and uses a message queue to orchestrate the
                tasks & workflows. Containerization provides the option to scale
                the application to infinity.
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} className={classes.principlesBox}>
              <Box className={classes.principlesInnerBox}>
                <Box className={classes.principlesImageBox}>
                  <img
                    src={dynamic}
                    alt="logo"
                    className={classes.Principlesimg}
                  />
                </Box>
                
                <Typography
                  variant="h4"
                  sx={{ mt: 1, mb: 1 }}
                  className={classes.principlesTitle}
                >
                  Dynamic Model Selection and Task Flexibility
                </Typography>
              </Box>

              <Typography variant="body2" className={classes.principlesContent}>
                {`${process.env.REACT_APP_NAME}`} provides an option to
                dynamically pick the models of your choice. It also provides the
                It also provides the option to pick up the tasks like
                transcription, translation and voice-over.
                <br />
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} className={classes.principlesBox}>
              <Box className={classes.principlesInnerBox}>
                <Box className={classes.principlesImageBox}>
                  <img
                    src={extensible}
                    alt="logo"
                    className={classes.Principlesimg}
                  />
                </Box>
                
                <Typography
                  variant="h4"
                  sx={{ mt: 1, mb: 1 }}
                  className={classes.principlesTitle}
                >
                  Extending Capabilities with Ease in Chitraanuvaad
                </Typography>
              </Box>

              <Typography variant="body2" className={classes.principlesContent}>
                Any new models can be plugged in to the system and micro-service
                & cloud-agnostic based architecture gives flexibility to extend
                other capabilities with minimal code changes
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} className={classes.principlesBox}>
              <Box className={classes.principlesInnerBox}>
                <Box className={classes.principlesImageBox}>
                  <img
                    src={simplicity}
                    alt="logo"
                    className={classes.Principlesimg}
                  />
                </Box>
                
                <Typography
                  variant="h4"
                  sx={{ mt: 1, mb: 1 }}
                  className={classes.principlesTitle}
                >
                  Easy-to-Use Portal for Teams and Individuals
                </Typography>
              </Box>

              <Typography variant="body2" className={classes.principlesContent}>
                {`${process.env.REACT_APP_NAME}`} portal is simple and explicit.
                Role based hierarchical gives the flexibility to work both as a
                team or individual. Most of the configurations and features can
                be easily tuned
                <br />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default Principles;
