import React, { useEffect, useRef, useState } from 'react'
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { Grid, IconButton, Typography } from "@mui/material"

export const FileUpload = ({ file:fileData, onFileChange, allowedExtensions, otherClassName }) => {
    const fileInputRef = useRef(null);
    const [dragging, setDragging] = useState(false);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            onFileChange(file)
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragging(false);
        const file = event.dataTransfer.files[0];
        if (file && (file.type === 'video/mp4' || file.type === 'video/webm')) {
            onFileChange(file)
        } else {
            console.error('Invalid file type');
        }
    };

    return <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={otherClassName ?? ''}
        style={{ width: '100%', height: '165px', border: "1px dotted black", borderRadius: '4px', display: dragging || fileData?.name ? 'flex' : "inherit", justifyContent: 'center', alignItems: 'center' }}
    >
        {!fileData?.name ? <Grid container spacing={2} alignItems="center" justifyContent="center" flexDirection="column" className={otherClassName ?? ''}>
            {dragging ?
                <Grid item>
                    <Typography fontSize="14px">Drop your files here</Typography>
                </Grid> :
                <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <IconButton onClick={handleButtonClick}>
                            <BackupOutlinedIcon color='primary' fontSize="large" style={{ cursor: 'pointer' }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography fontSize="14px">Drag your file(s) or <Typography style={{ cursor: 'pointer' }} component="span" fontSize="14px" color="#1849D6" onClick={handleButtonClick}>browse</Typography></Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography fontSize="14px" color="#6D6D6D">Max 100 MB files are allowed</Typography>
                    </Grid>
                </>
            }
        </Grid> : <Typography>Files dropped</Typography>}
        <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} accept={allowedExtensions} />
    </div>
}