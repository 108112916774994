import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Styles
import { LoginStyle } from "styles";

//Components
import {
  Grid,
  Typography,
  Link,
  InputAdornment,
  FormHelperText,
  IconButton,
  Button,
} from "@mui/material";
import AppInfo from "./AppInfo";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { OutlinedTextField, CustomizedSnackbars } from "common";

//APIs
import { validateEmail } from "utils/utils";
import OnboardingSignupAPI from "redux/actions/api/User/OnboardingSignup";
import { useDispatch, useSelector } from "react-redux";
import { APITransport, setSnackBar } from "redux/actions";

import C from "redux/constants.js";

const SignUp = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_email } = useParams();

  const apiStatus = useSelector((state) => state.apiStatus);
  const snackbar = useSelector((state) => state.commonReducer.snackbar);

  const classes = LoginStyle();

  const [values, setValues] = useState({
    email: user_email,
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const [error, setError] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: false });
  };

  const handleSubmit = () => {
    const apiObj = new OnboardingSignupAPI({ email: values.email, password: values.password });
    dispatch(APITransport(apiObj));
  };

  useEffect(() => {
    if (apiStatus.apiType === C.ONBOARDING_SIGNUP && !apiStatus.loading) {
      dispatch(setSnackBar({ open: true, message: apiStatus.message, variant: apiStatus.errors ? "error" : "success" }));

      const redirectTimeout = setTimeout(() => {
        window.open(apiStatus?.data?.redirect_url, '_blank');
      }, 5000);

      return () => clearTimeout(redirectTimeout);
      
    }
  }, [apiStatus])

  const HandleSubmitValidate = () => {
    if (!validateEmail(values.email)) {
      setError({ ...error, email: true });
    } else if (!(values.password.length > 7)) {
      setError({ ...error, password: true });
    } else if (values.password !== values.confirmPassword) {
      setError({ ...error, confirmPassword: true });
    } else {
      handleSubmit();
    }
  };

  const handleAlreadyhaveaccount = () => {
    localStorage.clear();
    navigate("/login");
  };

  const renderSnackBar = useCallback(() => {
    return (
      <CustomizedSnackbars
        open={snackbar.open}
        handleClose={() =>
          dispatch(setSnackBar({ open: false, message: "", variant: "" }))
        }
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        variant={snackbar.variant}
        message={[snackbar.message]}
      />
    );

    //eslint-disable-next-line
  }, [snackbar]);

  const TextFields = () => {
    return (
      <Grid container spacing={2} style={{ width: "40%" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h3" align="center">
            Create a new account
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OutlinedTextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            placeholder="Enter your Email ID."
            // onChange={handleChange}
            error={error.email ? true : false}
            value={values.email}
            helperText={error.email ? "Invalid email" : ""}
            sx={{backgroundColor: "#e8e8e8"}}
            // disabled={true}
            InputProps={{
              readOnly: true, // Disable user input
              disableUnderline: true, // Remove underline
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OutlinedTextField
            fullWidth
            name="password"
            label="Password"
            placeholder="Enter your Password."
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            error={error.password ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {values.showPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error.password && (
            <FormHelperText error={true}>
              Minimum length is 8 characters with combination of uppercase,
              lowercase, number and a special character
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OutlinedTextField
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Re-enter your Password."
            type={values.showConfirmPassword ? "text" : "password"}
            error={error.confirmPassword ? true : false}
            value={values.confirmPassword}
            onChange={handleChange}
            helperText={
              error.confirmPassword ? "Both password must match." : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowConfirmPassword}>
                    {values.showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            fullWidth
            onClick={() => HandleSubmitValidate()}
            variant="contained"
          >
            Submit
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.createLogin}>
            <Typography variant={"body2"} className={classes.Typo}>
              Already have an account ?
            </Typography>
            <Typography variant={"body2"}>
              <Link
                className={classes.link}
                onClick={handleAlreadyhaveaccount}
                style={{ fontSize: "14px" }}
              >
                Sign in
              </Link>
            </Typography>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.loginGrid}>
      {renderSnackBar()}
      <Grid
        item
        xs={12}
        sm={3}
        md={3}
        lg={3}
        color={"primary"}
        className={classes.appInfo}
      >
        <AppInfo />
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={9} className={classes.parent}>
        {TextFields()}
      </Grid>
    </Grid>
  );
};

export default SignUp;
