import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

import { googlePlay, appleStore, qr1, qr2, phone } from "assets";
import { IntroDatasetStyle } from "styles";

const Download = () => {
  const classes = IntroDatasetStyle();

  return (
    <Box className={classes.downloadWrapper}>
      <Grid
        container
        className={classes.downlaodTopContainer}
        sx={{ maxWidth: "1320px" }}
      >
        <Grid item xs={12} sx={{ mb: 6 }}>
          <Typography className={classes.downloadTopText}>
            Breaking Language Barrier Across India
          </Typography>
          <Typography className={classes.downloadBottomText}>
            Speak Your Language, All of India Understands!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.downloadText}>
            Download bhashini App
          </Typography>

          <Box>
            <img
              src={googlePlay}
              alt="googlePlay"
              style={{ width: "186px", marginRight: "70px" }}
            />
            <img
              src={appleStore}
              alt="appleStore"
              style={{ width: "186px", marginRight: "70px" }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{ background: "linear-gradient(90deg, #FEA96C 0%, #FD7F23 100%)" }}
      >
        <Grid
          container
          className={classes.downlaodBottomContainer}
          sx={{ display: { xs: "none", md: "flex" }, maxWidth: "1320px" }}
        >
          <Grid item xs={12} sx={{ display: "flex" }}>
            <img src={qr1} alt="qr1" />
            <Divider
              orientation="vertical"
              className={classes.downloadDivider}
            />
            <img src={qr2} alt="qr2" />
          </Grid>
        </Grid>
      </Box>

      <img src={phone} alt="phone" className={classes.downloadPhoneImage} />
    </Box>
  );
};

export default Download;
