import { makeStyles } from '@mui/styles'; // Importing makeStyles function from @mui/styles

const FooterStyles = makeStyles({
  FooterNewDesignContainer: {
    background: '#F5F7FA', // Setting background color
    paddingTop: '20px', // Adding top padding
  },
  FooterNewDesignManagement: {
    display: 'flex', // Making it a flex container
    flexDirection: 'column', // Aligning items vertically
    alignItems: 'flex-start', // Aligning items to the start
    padding: '0px', // Removing padding
    gap: '20px', // Setting gap between children
    '@media (max-width: 650px)': {
      gap: '5px', // Changing gap for smaller screens
    },
  },
  FooterNewDesignHeading: {
    fontWeight: 700, // Setting font weight
    fontSize: '20px', // Setting font size
    lineHeight: '28px', // Setting line height
    color: '#212121', // Setting text color
  //  width:"40%",
    textAlign:"start"
  },
  FooterNewDesignContent: {
    fontWeight: 400, // Setting font weight
    fontSize: '16px', // Setting font size
    lineHeight: '24px', // Setting line height
    color: '#000000', // Setting text color
    display: 'flex', // Making it a flex container
    textAlign:'start',
    alignItems:"center",
    '& a, .FooterNewDesignBottomPolicyTerms a': {
      textDecoration: 'none', // Removing underline from links
      color: '#000000', // Setting link color
      textAlign : 'start',
      fontSize:"16px",
    },
  },
  FooterNewDesignBottomLinks: {
    padding: "0 0.75rem",
    display: 'flex', // Making it a flex container
    justifyContent: 'space-between', // Spacing items evenly
    alignItems: 'center', // Aligning items to the center
    margin: '1.5rem 0rem 1.5rem 0rem', // Setting margin
    '@media (max-width: 550px)': {
      justifyContent: 'center !important', // Centering items for smaller screens
      display: 'flex', // Making it a flex container
      flexDirection: 'column', // Aligning items vertically
      paddingBottom: '10px', // Adding bottom padding
      gap: '20px', // Setting gap between children
    },
},
    FooterNewDesignBottomLinksName: {
      fontWeight: 700, // Setting font weight
      fontSize: '28px', // Setting font size
      lineHeight: '35px', // Setting line height
      color: '#fcfaff', // Setting text color
      marginRight: '15px', // Adding right margin
      display: 'flex', // Making it a flex container
      '& a': {
        textDecoration: 'none', // Removing underline from links
      },
    },
      Footer_btn: {
        width: '255px', // Setting width
        backgroundColor: '#FFFFFF', // Setting background color
        border: '1px solid #0671E0', // Setting border
        color: '#0671E0', // Setting text color
        fontSize: '16px', // Setting font size
        textAlign: 'center', // Aligning text to the center
      
        padding: '8px 16px', // Adding padding
        borderRadius: '4px', // Adding border radius
        WebkitBorderRadius: '4px', // Adding border radius for webkit
        MozBorderRadius: '4px', // Adding border radius for moz
        MsBorderRadius: '4px', // Adding border radius for ms
        OBorderRadius: '4px', // Adding border radius for o
      },
    FooterSocialMedia: {
      display: 'flex', // Making it a flex container
      justifyContent: 'center', // Aligning items to the center
      alignItems: 'center', // Aligning items to the center
      gap: '15px', // Setting gap between children
    },
      FooterSocialMedia_Heading: {
        fontSize: '20px', // Setting font size
     
        marginRight: '5px', // Adding right margin
        '@media (max-width: 650px)': {
            fontSize: '16px', // Changing gap for smaller screens
          },
      },
      FooterSocialMediaImg: {
        height: '30.77px', // Setting height
        width: '30.9px', // Setting width
      },

  FooterNewDesignBottomLinks1: {
  
    margin: '1rem 0rem', // Setting margin
    backgroundColor: '#FFFFFF', // Setting background color
    padding: '10px 80px', // Adding padding
    margin: '0px', // Removing margin
    '@media (max-width: 650px)': {
      justifyContent: 'center', // Centering items
      flexDirection: 'column', // Aligning items vertically
      gap: '20px', // Setting gap between children
      padding: '20px', // Adding padding
    },
},
    FooterNewDesignBottomLinkSeparator: {
      display: 'flex', // Making it a flex container
      justifyContent: 'space-between', // Spacing items evenly
      alignItems: 'center', // Aligning items to the center
      width: '100%', // Setting width
      paddingBottom: '10px', // Adding bottom padding
      borderBottom: '1px solid #ABBED1', // Adding bottom border
      '@media (max-width: 650px)': {
        flexDirection: 'column', // Aligning items vertically for smaller screens
      },
    },
    FooterNewDesignBottomLinksSection: {
      display: 'flex', // Making it a flex container
      justifyContent: 'center', // Aligning items to the center
      flexDirection: 'column', // Aligning items vertically
      alignItems: 'center', // Aligning items to the center
      gap: '20px', // Setting gap between children
    },
    FooterNewDesignManagement: {
      marginTop: '10px', // Adding top margin
      marginBottom: '10px', // Adding bottom margin
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    FooterNewDesignBottomLinks2Name: {
      fontWeight: 500, // Setting font weight
      fontSize: '16px', // Setting font size
      lineHeight: '19px', // Setting line height
      width: '868px', // Setting width
      color: '#e9d7fe', // Setting text color
    },
    FooterNewDesignBottomPolicyTerms: {
      display: 'flex', // Making it a flex container
      fontWeight: 500, // Setting font weight
      fontSize: '16px', // Setting font size
      lineHeight: '19px', // Setting line height
      color: '#e9d7fe', // Setting text color
    },
    FooterNewDesignLinksColor: {
      color: 'black', // Setting text color
      fontSize:'18px',
      textAlign:"start",
      fontWeight:"400",
      '@media (max-width: 650px)': {
        fontSize:"15px"
      }
    },
  FooterCopyRight: {
  display: 'flex', // Making it a flex container
  flexDirection: 'column', // Aligning items vertically
  justifyContent: 'center', // Aligning items to the center
  alignItems: 'center', // Aligning items to the center
  gap: '5px', // Setting gap between children
  padding: '0px 0px 10px 0px', // Adding padding
  backgroundColor: '#FFFFFF', // Setting background color
  '@media screen and (max-width:600px)': {
    paddingBottom: '20px', // Adjusting padding for smaller screens
  },
},

FooterLogo :{
    objectFit: 'contain',
    width: '90%',
},
  // border-top: '1px solid #ABBED1', // Adding top border
  FooterCopyRightHeading: {
    fontSize: '12px', // Setting font size
   
    color: '#89939E', // Setting text color
  },
  FooterCopyRightDesc: {
    fontSize: '14px', // Setting font size
   
    color: '#212121', // Setting text color
  },
});

export default FooterStyles; // Exporting useStyles function
