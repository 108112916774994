import React from "react";

//Styles
import { IntroDatasetStyle } from "styles";
import { introTheme } from "theme";

//Components
import { Grid, Typography, ThemeProvider } from "@mui/material";
import {
  purePython,
  openSource,
  easeToUse,
  cleanPowerful,
  robust,
} from "assets";

const featureList = [
  {
    image: purePython,
    heading: "Pure Python",
    content: `Chitraanuvaad is purely built on technologies supported by Python. This offers wide variety of tech stack to be easily integrated. The cloud agnostic stack also provides the flexibility to host in any on-prem or cloud platforms.`,
  },
  {
    image: cleanPowerful,
    heading: "Clean & Powerful UI",
    content: `Easy to generate transcript and translation subtitles and voice-over in translation language for a video, easy to do workflow management and performance tracking of tasks.`,
  },
  {
    image: robust,
    heading: "Robust Integrations",
    content: `Chitraanuvaad provides many plug-and-play features that are ready to execute your digitization & translation tasks using any of the available models. This makes Chitraanuvaad easy to apply to current infrastructure and extend to next-gen technologies.`,
  },
  {
    image: openSource,
    heading: "Open Source",
    content: `Wherever you want to share your improvement you can do this by opening a PR. It's simple as that, no barriers, no prolonged procedures. Chitraanuvaad has many active users who willingly share their experiences. Have any questions? Reach out to us via github or email.`,
  },
  {
    image: easeToUse,
    heading: "Easy to Use",
    content: `Anyone with basic knowledge in language can be onboarded to Chitraanuvaad. It does not limit the scope of your tasks; you can use it to build pipelines to generate the final subtitles and voice-over for the submitted videos.`,
  },
];

const Features = () => {
  const classes = IntroDatasetStyle();

  return (
    <ThemeProvider theme={introTheme}>
      <Grid item xs={12} sx={{ mt: 10 }} className={classes.featuresWrapper}>
        <Typography variant="h4" className={classes.titles}>
          Features
        </Typography>

        {featureList.map((item, index) => (
          <Grid
            container
            key={item.heading}
            direction={index % 2 === 0 ? "row" : "row-reverse"}
            justifyContent="center"
            alignItems="center"
            marginTop={{xs:"10%", sm: "10%", md: "4%"}}
            paddingX={{md:20, lg: 30}}
          >
            <Grid item xs={12} sm={12} md={6} maxWidth={{sm: "none", xs: "none"}}>
              <img
                src={item.image}
                alt="logo"
                className={classes.featuresimg}
                // style={{ width: "70%", maxWidth: "400px" }} // Adjust image width
              />
            </Grid>

            <Grid item xs={12} md={6} textAlign={{xs:"center", sm:"center", md:"left", lg: "left"}}>
              <Typography variant="h4" className={classes.featuresTitle}>
                {item.heading}
              </Typography>

              <Typography variant="body1" className={classes.featuresContent}>
                {item.content}
              </Typography>
            </Grid>
          </Grid>
        ))}

      </Grid>
    </ThemeProvider>
  );
};

export default Features;
