import { makeStyles } from "@mui/styles";

const IntroDatasetStyle = makeStyles({
  Chitralekhalogo: {
    width: "100%",
    height: "100%",
    margin: "0px 0px -7px 0px",
  },
  section: {
    backgroundColor: `#DBEDFF`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "10% 0 0 0",
    display: "flex",
    alignItems: "center",
    // height: "700px",
    "@media (max-width:700px)": {
      height: "auto",
      padding: "20% 3% 3% 3%",
    },
  },
  headerbtn: {
    color: "#51504f",
    textTransform: "capitalize",
    fontSize: "16px",
    fontFamily: "roboto,sans-serif",
  },
  Chitralekhatitle: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "44px",
    color: "#212121",
    textAlign: "left",
    fontFamily: "inter",
    margin: "0 0 25px 12px",
    "@media (max-width:700px)": {
      fontSize: "35px",
    },
  },
  footerGridMain: {
    backgroundColor: "#51504f",
    padding: "20px",
    color: "white",
    display: "grid",
    textAlign: "end",
  },
  footerGridMains: {
    backgroundColor: "#51504f",
    color: "white",
    display: "flex",
    textAlign: "start",
  },
  footerGrid: {
    backgroundColor: "#636365",
    padding: "3px 45px 3px 3px",
    color: "white",
    "@media (max-width:1200px)": {
      padding: "3px 3px 3px 3px",
    },
  },
  footerGridlast: {
    backgroundColor: "#636365",
    padding: "3px 0px 3px 60px",
    color: "white",
    textDecoration: "none",
    "@media (max-width:1200px)": {
      padding: "3px 3px 3px 3px",
    },
  },
  hover: {
    "&:hover": {
      textDecoration: "underline",
    },
  },

  partnersPaper: {
    padding: "2px 4px",
    display: "block",
    alignItems: "center",
    width: "250px",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  featuresimg: {
    width: "auto",
    height: "auto",
    aspectRatio: 1,
  },
  titles: {
    fontSize: "28px",
    lineHeight: "36px",
    color: "#000",
    fontFamily: '"rubik" ,"Roboto","Rowdies" ,sans-serif',
    fontWeight: "600",
  },
  featuresTitle: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 600,
    color: "#000",
    // width: "62%",
  },
  principlesContent: {
    fontSize: "16px",
    color: "#000",
    lineHeight: "24px",
    textAlign: "left",
  },
  featuresContent: {
    // textAlign: "left",
    fontSize: "16px",
    color: "#212121",
    marginTop: "14px",
  },
  principlesTitle: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 600,
    color: "#000",
    justifyContent: "center",
    width: "62%",
    textAlign: "left",
    marginLeft: "16px",
  },
  homeLink: {
    color: "#000",
    textDecoration: "underline",
    "&:hover": {
      color: "#000",
      textDecoration: "underline",
    },
  },
  description: {
    fontSize: "1.25rem",
    lineHeight: "2rem",
    margin: "0 35px 25px 0",
    textAlign: "justify",
  },
  Principlesimg: {
    width: "50px",
  },
  footerimg: {
    width: "40px",
    margin: "20px 0px 12px 35px",
    borderRadius: "15%",
    maxHeight: "40px",
  },
  buttons: {
    textTransform: "capitalize",
    backgroundColor: "rgb(44, 39, 153)",
    borderRadius: "5px",
    display: "flex",
    marginLeft: "42px",
    fontSize: "16px",
    fontFamily: "roboto,sans-serif",
    height: "35px",
    marginTop: "8px",
    marginRight: "30px",
    padding: "22px",
    "&:hover": {
      backgroundColor: "#04115E",
    },
  },
  thanks: {
    color: "white",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    userImg: {
      maxHeight: "300px",
      maxWidth: "200px",
      borderRadius: "50%",
      marginTop: "5px",
    },
  },
  button: {
    backgroundColor: "#0671E0",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "inter",
    height: "35px",
    // marginTop: "8px",
    marginRight: "30px",
    borderRadius: "4px",
    padding: "22px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#0671E0",
    },
  },
  usecaseImg: {
    width: "150px",
    margin: "25px 50px 20px 0px",
    float: "left",
    fontSize: "90px",
    color: "#2E86C1",
  },
  usecaseTitle: {
    display: "flex",
    fontWeight: 800,
    color: "#51504f",
    letterSpacing: "1px",
    fontSize: "24px",
    "@media (max-width:990px)": {
      lineHeight: "19px",
      fontSize: "20px",
    },
  },
  usecaseSubTitle: {
    display: "flex",
    fontWeight: 800,
    lineHeight: "1.17px",
    color: "#51504f",
    letterSpacing: "1px",
    fontSize: "20px",
  },
  usecaseCard: {
    width: window.innerWidth * 0.8,
    minHeight: 500,
    border: "1px solid #CCD1D1",
  },
  usecaseContent: {
    fontSize: "16px",
    color: "#707070",
    lineHeight: "25px",
    textAlign: "justify",
  },
  usecaseFeatures: {
    width: "100%",
    marginLeft: "20px",
    textAlign: "justify",
    fontSize: "16px",
    color: "#707070",
    padding: 0,
  },
  usecaseNote: {
    width: "100%",
    textAlign: "justify",
    fontSize: "12px",
    color: "#707070",
    fontWeight: 600,
    border: "1px solid black",
    padding: 5,
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  testimonialCardsWrapper: {
    width: "70%",
    height: "auto",
    margin: "auto",
    borderRadius: "20px",
    backgroundColor: "#26262633",
    textAlign: "left",
    fontSize: 20,
    color: "#000",
  },
  headerButtons: {
    color: "#51504f",
    textTransform: "capitalize",
    fontSize: "16px",
    fontFamily: "roboto,sans-serif",
    marginLeft: "24px",
  },
  poweredByEkstep: {
    position: "absolute",
    bottom: 50,
    right: 1,
    left: 1,
    fontSize: "0.7rem",
    fontWeight: "500",
    color: "#000000",
    marginTop: "auto",
  },
  headerDrawerButton: {
    color: "#51504f",
    textTransform: "capitalize",
    fontSize: "16px",
    fontFamily: "roboto,sans-serif",
  },
  headerMenu: {
    padding: "10px 15px",
    width: "auto",
    height: "auto",
    color: "#3A3A3A",
    fontSize: "16px",
    fontFamily: "inter",
    fontWeight: "normal",
    borderBottom: "3px solid #F3F3F3",
    borderRadius: 0,
    "&:hover": {
      borderBottom: "3px solid #FF7F11",
      background: "none",
    },
  },

  homeHeaderMenu: {
    color: "#000",
    fontWeight: "700",
    borderBottom: "3px solid #FF7F11",
  },

  testimonialWrapper: {
    width: "75%",
    borderRadius: "10px",
    padding: "10px",
  },
  testimonialContent: {
    textAlign: "justify",
    fontSize: "16px",
    color: "rgb(44, 39, 153)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  testimonialPageContent: {
    textAlign: "justify",
    fontSize: "16px",
    color: "rgb(44, 39, 153)",
  },
  testimonialContent2: {
    fontSize: "16px",
    color: "rgb(44, 39, 153)",
    textAlign: "right",
  },

  principlesBox: {
    border: "1px solid #89939E",
    borderRadius: "8px",
    padding: "24px",
  },

  principlesInnerBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "14px",
  },

  principlesImageBox: {
    background: "#F5F7FA",
    padding: "8px",
    borderRadius: "8px",
  },

  featuresWrapper: {
    background: "#F5F7FA",
    padding: "80px",
  },

  downloadWrapper: {
    position: "relative",
  },

  downlaodTopContainer: {
    textAlign: "left",
    display: "flex",
    margin: "auto",
    padding: "100px 0 30px 12px",
  },

  downlaodBottomContainer: {
    textAlign: "left",
    margin: "auto",
    padding: "40px 0 30px 12px",
  },

  downloadPhoneImage: {
    position: "absolute",
    top: "50px",
    "@media (max-width: 1024px)": {
      display: "none",
    },
  },

  downloadTopText: {
    fontSize: "36px",
    fontWeight: "600",
    lineHeight: "44px",
    "@media (max-width: 1024px)": {
      width: "100%",
    },
  },

  downloadBottomText: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212121",
    margin: "10px 0"
  },

  downloadText: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#212121",
    marginBottom: "16px",
  },

  downloadDivider: {
    margin: "auto 35px",
    background: "#fff",
    height: "150px",
  },
});

export default IntroDatasetStyle;
