import { useEffect } from "react"; // Importing useEffect hook
import {
  useMediaQuery,
  Container,
  Grid,
  ThemeProvider,
  Box,
} from "@mui/material"; // Importing required components from @mui/material
import { FooterStyles } from "styles";
import { FootersData, socialMedia } from "utils/footerDatas";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Meity from "../assets/Footer/MeityBlack.svg";
import BhashiniImg from "../assets/Footer/BhashiniBlack.svg";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Arrow from '../assets/arrow_forward_ios.svg'
import { introTheme } from "theme";
import { Margin } from "@mui/icons-material";

const FooterNewDesign = () => {
  const isMobile = useMediaQuery("(max-width:600px)"); // Checking if the device is mobile
  const classes = FooterStyles(); // Using styles

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));



  return (
    <ThemeProvider theme={introTheme}>
      <div className={classes.FooterNewDesignContainer}>
        <Box sx={{ padding: isMobile ? "0px 30px" : "0" }} style={{maxWidth: "1320px", margin: "auto", width: "100%"}}>
          <Grid container>
            {FootersData.map((data) => (
              <Grid xs={data?.sizeMob} md={6} lg={data?.size} className={classes.footerCol}>
                <div className={classes.FooterNewDesignManagement} style={{ gap :  data?.title !== "Contact Us" ? "20px"  :"0px"}}>
                  {data?.title === "Prayog" ||
                  data?.title === "Sahyogi" ||
                  data?.title === "Sanchalak" ? (
                    <BootstrapTooltip title={data?.tooltipData} placement="top">
                      <div className={classes.FooterNewDesignHeading}>
                        {data.title}
                      </div>
                    </BootstrapTooltip>
                  ) : (
                    <div className={classes.FooterNewDesignHeading}>
                      {data.title}
                    </div>
                  )}
                  {data.links.map((value) => (
                    <div className={classes.FooterNewDesignContent}>
                      {isMobile ||
                      value.text === "State gov" ||
                      value.text === "Mitra" ||
                      value.text === "Udayat" ||
                      value.text === "Vanianuvaad" ||
                      value.text === "Lekhaanuvaad" ? (
                      
                        data?.title !== "Contact Us" ?  <a href={value.link} >
                          <div className="py-1 FooterNewDesignLinksColor"  dangerouslySetInnerHTML={{
                      __html: value?.text,
                    }} /> 
                            {" "}                         
                            {/* {value.text}
                          </div> */}
                        </a> :   <div className="py-1 FooterNewDesignLinksColor" dangerouslySetInnerHTML={{
                      __html: value?.text,
                    }} /> 
                      ) : (
                        data?.title !== "Contact Us"  ? <Link to={value.link}  >
                          <div className="py-1 FooterNewDesignLinksColor" dangerouslySetInnerHTML={{
                      __html: value?.text,
                    }} /> 
                            {" "}                          
                            {/* {value.text} */}
                          {/* </div> */}
                        </Link> : (value.icon === "check" ?
                        <>
                        <span className="py-1 FooterNewDesignLinksColor" style={{width:value.icon === "check" ?"40%" :"100%"}}  dangerouslySetInnerHTML={{
                          __html: value?.text ,
                        }} /><span style={{marginTop:"3px"}}><img src={Arrow}/></span>
                        </>
                        : <div className="py-1 FooterNewDesignLinksColor" style={{width:"100%"}}  dangerouslySetInnerHTML={{
                      __html: value?.text,
                    }} /> )
                      )}
                    </div>
                  ))}
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ padding: isMobile ? "0px 30px" : "0" }} style={{maxWidth: "1320px", margin: "auto", width: "100%"}}>
          <hr className="my-2 my-md-2" style={{ color: "#ABBED1", margin: "0.5rem 0", opacity: "0.25"  }} />
          <div className={classes.FooterNewDesignBottomLinks}>
            <div className={classes.FooterNewDesignBottomLinksSection}>
              <span className={classes.FooterNewDesignBottomLinksName}>
                <a
                  href="https://bhashini.gov.in/bhashadaan/en/home"
                  target="_blank"
                >
                  <div className={classes.Footer_btn}>
                    Contribute to Bhashadaan
                  </div>
                </a>
              </span>
            </div>
            <div className={classes.FooterSocialMedia}>
              <div className={classes.FooterSocialMedia_Heading}>
                Connect with us
              </div>
              {socialMedia.map((data) => (
                <a href={data?.link} target="_blank">
                  <img
                    src={data?.image}
                    className={classes.FooterSocialMediaImg}
                  />
                </a>
              ))}
            </div>
          </div>
        </Box>

        <div className={classes.FooterNewDesignBottomLinks1}>
          <Grid style={{maxWidth: "1320px", margin: "auto", width: "100%"}}>
            <div className={classes.FooterNewDesignBottomLinkSeparator}>
              <div>
                <span className={classes.FooterNewDesignBottomLinks2Name}>
                  <div className={classes.media}>
                    <img
                      className={classes.FooterLogo}
                      src={Meity}
                      alt="meity-img"
                    />
                  </div>
                </span>
              </div>
              <div className={classes.FooterNewDesignBottomPolicyTerms}>
                <div className={classes.media}>
                  <img
                    className={classes.FooterLogo}
                    src={BhashiniImg}
                    alt="meity-img"
                  />
                </div>
              </div>
            </div>
          </Grid>
        </div>
        <div className={classes.FooterCopyRight}>
          <div className={classes.FooterCopyRightHeading}>
            Designed, Developed & Hosted by
          </div>
          <div className={classes.FooterCopyRightDesc}>
            Digital India Bhashini Division (DIBD)
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default FooterNewDesign;
